import { defineStore } from 'pinia'
import { stringify } from 'qs'

export const useArticleStore = defineStore('article', () => {
  const articlesByCategories = ref<any>([])
  const articles = ref<any>([])
  const articlesByRandom = ref<any>([])
  const sidebarArticles = ref<any>([])
  const article = ref<any>(null)

  const fetchArticlesByCategories = async ({ params }: any) => {
    const { data }: any = await useAPI(`/news/by-categories${stringify(params, {
      addQueryPrefix: true
    })}`, { key: 'articles.by.categories', lazy: true })

    if (data.value?.articles) {
      articlesByCategories.value = data.value.articles
    }
    return articlesByCategories.value
  }

  const fetchArticle = async ({ category, year, month, day, slug }: any) => {
    const { $api } = useNuxtApp()
    const { setAlternativeAds } = useAdStore()
    let query = `${category}/${year}/${month}/${day}/${slug}`
    query = encodeURIComponent(query)
    const response: any = await $api(`/news/${query}`)
    article.value = response
    if (response.isAlternativeAds === 1) {
      setAlternativeAds(true)
    }
    return article.value
  }

  const fetch = ({ params, categoryId = null, key = null, lazy = true }: any) => {
    if (categoryId) {
      params['filter.categoryId'] = categoryId
    }
    const url = `/news${stringify(params, { addQueryPrefix: true })}`
    return useAPI(url, { key, lazy })
  }

  const fetchArticles = async ({ params, categoryId = null }: any) => {
    if (categoryId) {
      params['filter.categoryId'] = categoryId
    }
    const url = `/news${stringify(params, { addQueryPrefix: true })}`
    const { $api } = useNuxtApp()

    const response: any = await $api(url)

    if (response?.data) {
      articles.value = response?.data
    }
    return articles
  }

  const fetchSidebarNews = async () => {
    const url = '/news/sidebar-items'

    const { data } = await useAPI(url, { key: 'sidebar.articles' })
    sidebarArticles.value = data.value
  }

  const fetchArticlesByRandom = async ({ params }: any) => {
    const { data: news } = await useAPI(
        `/news/by-random${stringify(params, {
          addQueryPrefix: true
        })}`, { key: 'random.articles' })
    articlesByRandom.value = news.value
  }

  const getArticlesByCategory = (categoryId: number) => {
    return articlesByCategories.value[categoryId] || []
  }

  return {
    fetch,
    fetchArticles,
    fetchArticlesByCategories,
    fetchArticlesByRandom,
    fetchSidebarNews,
    fetchArticle,
    articles,
    article,
    articlesByCategories,
    sidebarArticles,
    getArticlesByCategory,
    articlesByRandom
  }
})
